@import '../styles/styles.scss';

.footer {
	margin-top: 3em;
	padding: 1em 0;
	background: $Dark;
	color: $Light;
	text-align: center;
	box-shadow: inset 0px -2px 3px rgba(255, 255, 255, 0.5),
		inset 0px 3px 3px rgba(255, 255, 255, 0.5);

	.modal {
		background-color: $Light;
		height: 6em;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin: 0.5em 4em;
		border-radius: 1em;
		padding: 0.5em;

		span,
		p {
			color: $Dark;
		}
	}

	.modal.hidden {
		display: none;
	}

	.footer__open {
		@include font-size(18px, 28px);
		line-height: 1.5em;
	}

	p {
		@include font-size(14px, 21px);
		display: flex;
		justify-content: center;
		margin: 0.2em 0;
		letter-spacing: 0.5px;

		a {
			padding: 0;

			&:hover {
				transform: scale(1.1);
				color: $mainLight;
				// background-image: linear-gradient(45deg, $mainColor, $Dark);
				padding: 0 2em;
				// border-radius: 5px;
			}
		}
	}
	a {
		color: $Light;
		text-decoration: none;
		cursor: pointer;
		padding: 0.8em;
	}
	button {
		background-color: transparent;
		border: none;
		box-shadow: none;
		cursor: pointer;
		padding: 0.8em;
	}

	.footer-icon {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 100%;
		height: 2em;
		margin-top: 1em;
		margin-bottom: 1em;
		padding: 0 0.4em;

		a,
		p {
			margin: 0;
			padding: 0;
		}

		svg {
			fill: $Light;
			margin: 0;
			padding: 0;
			transition: all 0.3s ease;

			&:hover,
			:focus {
				transform: scale(1.2);
				fill: $mainLight;
			}
		}

		.blog {
			@include font-size(24px, 40px);
			padding: 0 0.5em;

			&:hover,
			:focus {
				transform: scale(1.2);
			}
		}
	}

	.copywrite {
		padding: 0 0.2em;

		p.linked {
			margin: 1.2em 0;
		}

		p.email {
			cursor: pointer;

			&:hover {
				transform: scale(1.1);
				color: $mainLight;
				padding: 0 2em;
			}
		}

		.policy-text {
			padding: 0 0.6em;
		}

		.policy {
			color: $Light;
			text-decoration: none;
			border-radius: 3px;
			padding: 0 0.6em;
			cursor: pointer;

			&:hover {
				color: $mainLight;
				background-color: transparent;
				// background-image: linear-gradient(45deg, $mainColor, $Dark);
				// padding: 0 0.4em;
				// border-radius: 5px;
			}
		}
	}
}

// NAVBAR FOR TABLETS & DESKTOPS
@include mQ(768px) {
	.footer {
		.footer-icon {
			justify-content: center;
			height: 3.5em;

			a,
			p {
				padding: 0 1.3em;
			}
		}
	}
}
