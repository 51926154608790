@import '../styles/styles.scss';

:root {
	--sm-icon: clamp(1.2rem, 4vw, 2.5rem);
	--md-icon: clamp(2rem, 6vw, 4rem);
	--lg-icon: clamp(3rem, 5.2vw, 5rem);
}

.skills-container {
	display: block;
	margin: 2rem 0.5rem;
	padding: 0.5rem;
	width: 100%;
	// border: 2px solid red;

	h2 {
		width: 100%;
		text-align: center;
	}

	h3 {
		margin: 0.5em;
	}

	.frontend,
	.backend,
	.database,
	.others {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
	}
}

.skills-container svg {
	width: var(--md-icon);
	margin: 0.5em;
}

// Laptop and Tablet
@media only screen and (min-width: 768px) {
	.skills-container {
		svg {
			margin: 1em;
		}
	}
}
