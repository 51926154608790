// IMAGES ASPECT RATIO:
// <div className='images__img-container-1x1'>
//     <img src={item.image} alt={`${item.title} - ${index}`} />
// </div>

body {
	.images__img-container-1x1 {
		position: relative;
		width: 100%;
		overflow: hidden;

		&::after {
			content: '';
			display: block;
			padding-top: calc(100% / (1 / 1));
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.images__img-container-6x4 {
		position: relative;
		width: 100%;
		overflow: hidden;

		&::after {
			content: '';
			display: block;
			padding-top: calc(100% / (6 / 4));
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.images__img-container-4x6 {
		position: relative;
		width: 100%;
		overflow: hidden;

		&::after {
			content: '';
			display: block;
			padding-top: calc(100% / (4 / 6));
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
