@import '../styles/styles.scss';

section.container-posts {
	width: 100%;
	min-height: 300px;
	margin: 0;
	margin-top: 2em;
	padding: 0;

	h2 {
		margin-top: 3em;
		margin-bottom: 1rem;
		color: $Light;
		text-align: center;
		font-family: $subTitle;
		font-weight: 500;
		text-shadow: $text-shadow;
	}
	h2 span {
		color: $mainColor;
		font-weight: 700;
	}

	h3 {
		margin: 0;
		margin-bottom: 1em;
		color: darken(white, 15);
		text-align: center;
		font-family: $subTitle;
		font-weight: bold;
		text-shadow: $text-shadow;
	}

	.container {
		width: 100%;
		// max-width: 1200px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin: auto;
		// border: 2px solid red;

		.post {
			@include size(380px, 540px);
			max-width: 98%;
			background: $MedDark;
			border-radius: 8px;
			border: 2px solid $MedDark;
			border-right: 2px solid $mainColor;
			border-bottom: 2px solid $mainColor;
			box-shadow: 0 2px 2px rgba(0, 0, 0, 0.8),
				inset 0px -2px 3px rgba(255, 255, 255, 0.5);
			overflow: hidden;
			margin: 0.5em;
			text-align: center;
			transition: all 0.25s;

			&:hover,
			&:focus {
				// transform: translateY(-15px);
				box-shadow: 0 12px 16px rgba(0, 0, 0, 0.8);
			}

			img {
				background: $Light;
				box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2);
			}

			hr {
				margin: 0.5em 0.2em;
			}

			h4 {
				@include font-size(26px, 30px);
				color: white;
				font-weight: 600;
				// text-transform: uppercase;
				margin: 0;
				padding: 0 0.3em;
			}

			h5,
			p {
				@include font-size(18px, 22px);
				letter-spacing: 1px;
				margin: 0;
				margin-top: 1em;
				// padding: 0 2em;
				color: $Light;
				font-weight: 300;

				span {
					color: $Light;
					font-weight: 600;
				}
			}

			a h5 {
				@include font-size(18px, 20px);
				font-weight: 700;
				margin: 0.8em 0.5em;
			}

			.subtitle {
				min-height: 3em;
			}

			.code-container {
				min-height: 6em;

				p.code {
					@include font-size(17px, 20px);
					color: darken($Light, 20%);
					margin-top: 0.1em;
					padding: 0 0.3em;
					letter-spacing: 1.4px;
				}
			}
		}
	}
}

// FOR TABLETS & DESKTOPS
@include mQ(768px) {
	section.container-posts {
		margin-top: 3em;
		.container {
			.post {
				margin: 0.6em;
			}
		}
	}
}
