// font-size( minSize, maxSize)

body{
  
    h1{
        font-family: $title; 
        @include font-size(28px, 60px);
        font-weight: 700;
        line-height: 1.1em;
    }
    h2{
        font-family: $subTitle; 
        @include font-size(22px, 48px);
        font-weight: 500;
        line-height: 1.2em;
    }
    h3{
        font-family: $subTitle2; 
        @include font-size(20px, 38px);
        font-weight: 400;
        line-height: 1.3em;
    }
    h4{
        font-family: $subTitle2;
        @include font-size(18px, 28px);
        font-weight: 400;
        line-height: 1.4em;
    }
    p, h5, h6{
        font-family: $body;
        @include font-size(16px, 21px);
        font-weight: 400;
        line-height: 1.5em;
    }
    
    .text__gradient {
        background: linear-gradient(-120deg, $mainDark 0%, $mainColor 50%, $mainColor 50%, $mainLight 100%);
        text-shadow: none;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
    }

    .text__gradient2 {
        background: linear-gradient(to right, $mainColor 0%, $mainLight 50%, $mainLight 50%, $mainColor 100%);
        text-shadow: none;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
    }

    .text__gold {
        background: linear-gradient(to right, #462523 0%, #cb9b51 22%, #f6e27a 45%, #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #462523 100%);
        text-shadow: none;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
    }
    
}
