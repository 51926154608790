@import '../styles/styles.scss';

section.container-timeline {
	display: flex;
	justify-content: center;
	width: 100%;
	min-height: 300px;
	margin: 0;
	padding: 0;

	.container-main {
		width: 100%;
		max-width: 1200px;
		display: block;
		text-align: center;

		h2 {
			margin-top: 0;
			color: $Light;
			font-family: 'Roboto', sans-serif;
			font-weight: bold;
			margin-bottom: 2em;
			text-shadow: $text-shadow;
		}

		.timeline {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				left: 20px;
				width: 2px;
				height: 100%;
				background: $mainColor;
			}

			h3 {
				color: $Light;
				margin: 0;
				margin-top: 1em;
			}

			.hardware {
				@include font-size(22px, 40px);
				font-weight: bold;
				color: $Light;
				margin: 0;
			}

			.software {
				color: darken($Light, 5);
				margin: 0;
				margin-bottom: 1em;
			}

			li {
				position: relative;
				margin: 0;
				padding: 1.5em 2.5em;
				list-style: none;

				.time {
					h4 {
						font-weight: bold;
						color: $Light;
					}
				}

				&:nth-child(odd),
				&:nth-child(even) {
					text-align: left;
					padding-left: 3em;
				}

				&:nth-child(odd)::before,
				&:nth-child(even)::before {
					content: '';
					position: absolute;
					top: 0px;
					left: 16px;
					width: 10px;
					height: 10px;
					background: $mainMDark;
					border-radius: 50%;
					box-shadow: 0 0 0 3px rgba($mainColor, 0.2);
				}

				&:nth-child(odd) .time,
				&:nth-child(even) .time {
					position: absolute;
					top: -10px;
					left: 2.5em;
					width: 8em;
					height: 2em;
					text-align: center;
					margin: 0;
					padding: 0.2em 1em;
					background: $mainMDark;
					color: $Light;
					border-radius: 1em;
					box-shadow: 0 0 0 3px rgba($mainColor, 0.3);
				}
			}
		}
	}
}

// FOR TABLETS & DESKTOPS

@include mQ(600px) {
	section.container-timeline {
		.container-main {
			h2 {
				margin-bottom: 1em;
			}

			.timeline {
				&::before {
					left: 50%;
				}

				h3 {
					margin: 0;
				}

				.software {
					margin: 0;
				}

				.hardware {
					margin-bottom: 0.1em;
				}

				li {
					width: 50%;
					position: relative;

					&:nth-child(odd) {
						float: left;
						text-align: right;
						clear: both;
					}

					&:nth-child(even) {
						float: right;
						text-align: left;
						clear: both;
					}

					&:nth-child(odd)::before {
						top: 2.8em;
						left: calc(100% - 5px);
					}

					&:nth-child(even)::before {
						top: 2.8em;
						left: -5px;
					}

					&:nth-child(odd) .time {
						width: 10em;
						height: 3em;
						top: 1.6em;
						left: calc(100% + 2em);
					}

					&:nth-child(even) .time {
						width: 10em;
						height: 3em;
						top: 1.6em;
						left: -12em;
					}
				}
			}
		}
	}
}
