@import '../styles/styles.scss';

section.content-slider {
	height: 30px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	margin-bottom: 0.5em;
	margin-top: 1em;
	overflow: hidden;
	padding-top: 2px;
	// border: 2px solid red;

	.slider-text {
		h4.slider {
			@include font-size(14px, 34px);
			color: $Light;
			margin: 1px 0;
			font-weight: bold;
			line-height: 30px;
			text-transform: uppercase;
		}
	}

	.slider-text.vertical {
		animation: slider-vertical 8s linear infinite;
	}

	@keyframes slider-vertical {
		0% {
			margin-top: 120px;
		}
		5% {
			margin-top: 60px;
		}
		30% {
			margin-top: 60px;
		}
		35% {
			margin-top: 0;
		}
		65% {
			margin-top: 0;
		}
		70% {
			margin-top: -60px;
		}
		100% {
			margin-top: -60px;
		}
	}
}

.content-slider.horizontal {
	.slider-text.horizontal {
		width: 300px;
		margin: 0 auto;
		display: flex;
		overflow: hidden;

		// border: 2px solid blue;

		h4 {
			width: 18em;
			margin: 0;
			animation: slider-horizontal 15s linear infinite;

			// border: 2px solid red;
		}
	}

	@keyframes slider-horizontal {
		0% {
			transform: translateX(18em);
		}
		25% {
			transform: translateX(0);
		}
		50% {
			transform: translateX(-18em);
		}
		75% {
			transform: translateX(-36em);
		}
		100% {
			transform: translateX(-54em);
		}
	}
}

// FOR TABLETS
@include mQ(768px) {
	section.content-slider {
		margin-bottom: 2em;
		margin-top: 0.5em;
	}
	.content-slider.horizontal {
		.slider-text.horizontal {
			width: 28em;

			h4 {
				width: 28em;
				animation: slider-horizontal 20s linear infinite;
			}
		}

		@keyframes slider-horizontal {
			0% {
				transform: translateX(28em);
			}
			25% {
				transform: translateX(0);
			}
			50% {
				transform: translateX(-28em);
			}
			75% {
				transform: translateX(-54em);
			}
			100% {
				transform: translateX(-82em);
			}
		}
	}
}

// FOR DESKTOPS
@include mQ(1000px) {
	section.content-slider.horizontal {
		.slider-text.horizontal {
			width: 28em;

			h4 {
				width: 30em;
				animation: slider-horizontal 24s linear infinite;
			}
		}

		@keyframes slider-horizontal {
			0% {
				transform: translateX(30em);
			}
			25% {
				transform: translateX(0);
			}
			50% {
				transform: translateX(-30em);
			}
			75% {
				transform: translateX(-60em);
			}
			100% {
				transform: translateX(-90em);
			}
		}
	}
}
