// Buttons
a {
	font-family: $buttons;
}

.btn {
	@include font-size(14px, 21px);
	font-family: $buttons;
	border-radius: 0.2em;
	cursor: pointer;
	display: inline-block;
	padding: 0 2em;
	text-decoration: none;
	letter-spacing: 1px;
	position: relative;
	overflow: hidden;
	transition: all 1s ease;
}

.btn__main {
	@include button-bg-ol($mainColor, $Dark);
	background: transparent;
	border: 2px solid $mainDark;
	color: $mainDark;
	line-height: calc(1vw + 1.9rem);
	margin: 1.5em 0.5em;
	z-index: 1;
}

.btn__mobile {
	@include button-bg($Dark, $mainColor);
	border: 1px solid $mainDark;
	color: $mainDark;
	background-color: transparent;
	line-height: calc(1vw + 1.2rem);
}

.btn__amazon {
	@include button-bg($Light, $mainColor);
	border: 1px solid black;
	color: black;
	background-color: $Amazon;
	line-height: calc(1vw + 1.2rem);
}

.btn__card,
.btn__card_code {
	@include button-click($Dark, $Light);
	background-color: $mainDark;
	border: 3px solid $Light;
	color: $Light;
	line-height: calc(1vw + 1.9rem);
	margin: 1.5em 0.5em;
	box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
	transition: 0.5s ease;
	z-index: 1;

	&:hover {
		background-color: $Info;
		box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.5);
	}
}
.btn__card_code {
	background-color: transparent;
}

.btn__block {
	width: 100%;
}

.btn__click {
	@include font-size(16px, 24px);
	margin: 0em 1em 1em;
	padding: 0.2em 1em;
	color: white;
	display: flex;
	align-items: center;
	background: $mainColor;
	box-shadow: $btn-shadow;
	border: 2px solid white;
	border-radius: 4px;
	outline: none;
	cursor: pointer;
	transition: 0.3s ease;
	z-index: 1;

	svg {
		@include size(22px, 36px, 1, 1);
		margin-right: 0.8em;
		fill: white;
	}

	&:active {
		transform: scale(0.8);
		background: $Dark;
		box-shadow: $btn-shadow-click;
	}
}

.btn__docs {
	@include font-size(16px, 24px);
	font-family: $buttons;
	min-width: 320px;
	max-width: 90%;
	padding: 0.2em 1em;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $mainColor;
	box-shadow: $btn-shadow;
	border: 2px solid white;
	border-radius: 4px;
	outline: none;
	cursor: pointer;
	transition: 0.5s ease;
	z-index: 1;

	svg {
		@include size(22px, 36px, 1, 1);
		margin-right: 0.8em;
		fill: white;
	}

	&:active {
		transform: scale(0.8);
		background: $Dark;
		box-shadow: $btn-shadow-click;
	}
}

.btn__docs__ol {
	@include font-size(16px, 24px);
	font-family: $buttons;
	padding: 0.2em 1em;
	min-width: 320px;
	max-width: 90%;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	background: none;
	box-shadow: $btn-shadow;
	border: 2px solid white;
	border-radius: 4px;
	outline: none;
	cursor: pointer;
	transition: 0.5s ease;
	z-index: 1;

	svg {
		@include size(22px, 36px, 1, 1);
		margin-right: 0.8em;
		fill: white;
	}

	&:active {
		transform: scale(0.8);
		background: $Dark;
		box-shadow: $btn-shadow-click;
	}
}

// btn-click & btn-docs for Desktop
@include mQ(1200px) {
	.btn__click,
	.btn__docs,
	.btn__docs__ol {
		&:hover {
			background-color: $Info;
			// background-color: $mainMDark;
			box-shadow: inset 3px 3px 5px rgba(255, 255, 255, 0.5);
		}
	}
}

.btn__social {
	@include font-size(16px, 21px);
	color: white;
	line-height: 1.5em;
	box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
	padding: 0.2em 0.8em;
	display: flex;
	justify-content: space-around;
	align-items: center;

	svg {
		@include size(20px, 24px, 1, 1);
		margin-right: 0.5em;
		path {
			fill: white;
		}
	}
}

.btn__facebook {
	@include button-bg($Light, blue);
	background-color: #3b5998;
	// margin-left: 1em;
}

.btn__whatsapp {
	@include button-bg($Light, lightgreen);
	background-color: #00bb2d;
	margin-left: 1em;
}

.btn__twitter {
	@include button-bg($Light, blue);
	background-color: #1da1f2;
	margin-left: 1em;
}

.btn__instagram {
	@include button-bg($Light, #8134af);
	background-color: #dd2a7b;
	margin-left: 1em;
}
