@import '../styles/styles.scss';

.btn-container {
	margin: 2rem;
	text-align: center;

	.btn {
		@include font-size(18px, 24px);
		padding: 0.5em 1.5em;
		background: $mainColor;
		color: white;
		border: none;
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
		transition: background scale 0.3s;

		&:hover,
		:focus {
			background-image: linear-gradient(45deg, $mainColor, $Dark);
			transform: scale(1.1);
		}
	}
}
