@import '../styles/styles.scss';

:root {
	--size-1: clamp(2rem, 6vw, 4rem);
	--size-2: clamp(1.5rem, 5vw, 3rem);
	--size-3: clamp(1.2rem, 4vw, 2.5rem);
	--size-4: clamp(1.1rem, 3.5vw, 2rem);
	--size-5: clamp(1rem, 3vw, 1.8rem);
	--size-6: clamp(0.9rem, 2.5vw, 1.5rem);
	--size-7: clamp(0.8rem, 2.2vw, 1.2rem);
	--size-8: clamp(0.7rem, 2.1vw, 1.1rem);

	--sm-shadow: 3px 3px 5px grey;
	--lg-shadow: 5px 5px 8px grey;

	--sm-icon: var(--size-3);
	--md-icon: var(--size-1);
	--lg-icon: clamp(3rem, 5.2vw, 5rem);
}

.about-container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: $Light;
	margin: 2rem auto;
	padding: 2rem;
	min-height: 83vh;
	overflow: hidden;
	user-select: none;
}

.about-container .about-title {
	font-weight: 700;
	margin-top: 1rem;
	margin-bottom: 0;
	color: $mainColor;
	border: none;
	background-color: transparent;
}

.about-container .about-title span {
	font-weight: 500;
	color: $Light;
}

.about-container .image-gif {
	width: 100%;
	height: 200px;
	margin-top: 4rem;
	margin-bottom: 3rem;
	background-image: url('../assets/images/giphy.gif');
	box-shadow: var(--sm-shadow);
}

.about-container p {
	margin: 0.5em 0.5em;
	padding: 0;
	line-height: 1.6;
	letter-spacing: 1px;
	// word-spacing: 0.05em;
	text-indent: 2em;
	font-weight: 300;
	text-align: left;
}

.about-container p span {
	font-weight: 700;
	font-style: italic;
	color: white;
}

.about-container h4 {
	margin: 0.5em 0;
}

.about-container h4 span {
	font-weight: 700;
	font-style: italic;
}

.about-container .links-container {
	margin-top: 3em;
	display: flex;
	flex-wrap: wrap;
	gap: 3em;
}

.about-container .web-link {
	font-size: var(--size-5);
	text-decoration: none;
	color: $mainColor;
	margin: 0 auto;
	padding: 0.3em 2em;
	border: 2px solid $mainColor;
	border-radius: 0.3rem;
	box-shadow: var(--sm-shadow);
	cursor: pointer;
}

.about-container .resume-link {
	font-size: var(--size-5);
	font-size: $buttons;
	text-decoration: none;
	color: $mainColor;
	border: 2px solid $mainColor;
	background-color: transparent;

	margin: 0 auto;
	margin-bottom: 3rem;
	padding: 0.3em 2.7em;
	border: 2px solid $mainColor;
	border-radius: 0.3rem;
	box-shadow: var(--sm-shadow);
	cursor: pointer;
}

.about-container .web-link:hover,
.about-container .resume-link:hover {
	color: $Light;
	background-color: $mainColor;
	box-shadow: 3px 3px 5px grey;
}

@media only screen and (max-width: 768px) {
	.about-container .links-container {
		gap: 1em;
	}

	.about-container {
		padding: 0.2rem;
	}

	.about-container .about-title {
		margin-top: 0;
	}

	.about-container .image-gif {
		height: 150px;
	}

	.about-container p {
		text-align: left;
		// text-align: justify;
		// text-justify: inter-word;
	}
}
