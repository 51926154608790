@import '../styles/styles.scss';

nav.navbar.navbar__active {
	background: $navbarColor;
	box-shadow: 0 2px 5px #777, 0 -2px 5px #999;
}

nav.navbar {
	position: fixed;
	top: 3px;
	left: 50%;
	transform: translateX(-50%);
	background: transparent;
	width: 100%;
	max-width: $maxWidth;
	height: 3em;
	padding: 0 1em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1000;
	transition: 0.4s ease;

	.logo {
		@include size(28px, 28px, 1, 1);
		margin-right: 0.2em;
		margin-left: 0.8em;
	}

	.navbar__logo {
		display: flex;
		align-items: center;

		.navbar__logo-icon {
			@include size(28px, 45px, 1, 1);
			margin-right: 0.2em;
			margin-left: 0.8em;
		}

		span {
			@include font-size(20px, 30px);
			font-family: $logo;
			font-weight: 700;
			color: white;
			background: $mainDark;
			border-radius: 5px;
			box-shadow: 0 0 10px rgba(250, 250, 250, 0.4);
			font-weight: 900;
			margin-left: 0.3em;

			&.logo-text {
				padding: 1px 15px;
			}
		}
	}

	.navbar__toggle {
		display: flex;
		align-items: center;

		svg {
			cursor: pointer;
			fill: $Light;
			@include size(28px, 40px, 1, 1);
			margin: 0 10px;
		}
		svg:hover,
		svg:focus {
			fill: $mainDark;
		}
		svg:active {
			transform: scale(0.95);
		}

		span {
			color: $Alert;
			margin-right: 1em;
			font-family: $subTitle;
			font-weight: 500;
			@include font-size(16px, 20px);
		}
	}

	.navbar__menu {
		position: fixed;
		top: 0;
		left: -110%;
		flex-direction: column;
		transition: 350ms;

		.navbar__menu-links {
			margin-right: 0.5em;

			.navbar__menu-link {
				color: $Light;
				@include font-size(16px, 24px);
				font-family: $navbar;
				font-weight: 600;
				padding: 0 0.5em;
				text-decoration: none;
				text-transform: uppercase;
				transition: 0.3s ease-out;

				&:hover {
					// color: $mainLight;
					background-image: linear-gradient(45deg, $mainColor, $Dark);
					padding: 0 1em;
					border-radius: 5px;
				}
			}
		}
	}
	.navbar__menu.navbar__show {
		display: block;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		height: 100vh;
		background: $Dark;
		text-align: center;
		padding: 6em 1em;
		z-index: -1;

		.navbar__menu-links {
			margin-bottom: 1em;
			.navbar__menu-link {
				@include font-size(28px, 36px);
			}
		}
	}
}

// NAVBAR FOR TABLETS & DESKTOPS
@include mQ(768px) {
	nav.navbar {
		background: $navbarColor;
		box-shadow: 0 2px 5px #777, 0 -2px 5px #999;

		.navbar__toggle {
			display: none;
		}

		.navbar__logo {
			span {
				&.logo-text {
					padding: 3px 18px;
				}
			}
		}

		.navbar__menu {
			left: 0;
			display: flex;
			position: relative;
			flex-direction: row;
		}
	}
}
