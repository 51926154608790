html {
	scroll-behavior: smooth;
	scroll-padding: 90px;
	overflow-y: scroll;
}

body {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	max-width: $maxWidth;
	margin: 0 auto;
	padding: 0;
	height: 100%;
	background-color: $Dark;
	overflow: hidden;
	background: #000000; /* fallback for old browsers */
	background: -webkit-linear-gradient(
		to right,
		#434343,
		#000000
	); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to right,
		#434343,
		#000000
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	header {
		margin-bottom: 2em;
	}

	.general__container {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0;
		// min-height: 100vh;
		overflow: hidden;
		// border: 2px solid violet;

		header {
			margin: 0;
			padding: 0;
			width: 100%;
		}

		main {
			margin: 0;
			padding: 0.5rem 1rem;
			width: 100%;
			// border: 2px solid blue;
		}

		footer {
			position: absolute;
			bottom: 0;
			margin: 0;
			padding: 0;
			width: 100%;
			// border: 2px solid green;
		}
	}

	@include mobile {
	}

	@include tablet {
	}
}
