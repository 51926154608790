@import '../styles/styles.scss';

.vmog-home {
	background: #000000; /* fallback for old browsers */
	background: -webkit-linear-gradient(
		to right,
		#434343,
		#000000
	); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to right,
		#434343,
		#000000
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	main {
		h1 {
			color: $Light;
			background-color: $Dark;
			box-shadow: inset 0px 2px 3px rgba(255, 255, 255, 0.5),
				inset 0px -2px 3px rgba(255, 255, 255, 0.5);
			font-family: $title;
			text-align: center;
			padding: 0.5em;
		}

		.link-container {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 3em;

			.project-link {
				font-size: var(--size-5);
				font-size: $buttons;
				text-decoration: none;
				color: $mainColor;
				border: 2px solid $mainColor;
				background-color: transparent;

				margin: 0 auto;
				margin-bottom: 3rem;
				padding: 0.3em 2.7em;
				border: 2px solid $mainColor;
				border-radius: 0.3rem;
				box-shadow: var(--sm-shadow);
				cursor: pointer;
			}

			.project-link:hover {
				color: $Light;
				background-color: $mainColor;
				box-shadow: 3px 3px 5px grey;
			}
		}

		.social-share {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
			margin: 0;
			margin-bottom: 0.5em;
			margin-top: 1em;
		}

		.social-share > * {
			margin-bottom: 1em;
		}
	}
}
