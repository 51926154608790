@import '../styles/styles.scss';

.scour__container {
	main {
		h1 {
			color: $Light;
			background-color: $Dark;
			box-shadow: inset 0px 2px 3px rgba(255, 255, 255, 0.5),
				inset 0px -2px 3px rgba(255, 255, 255, 0.5);
			font-family: $title;
			text-align: center;
			padding: 0.5em;
			margin-top: 10vh;
		}

		ul.page-links-container {
			list-style: none;
			padding: 2em;
			border: 2px solid $MedLight;

			h2 {
				color: $Light;
				margin: 0;
				// border: 2px solid red;
			}
		}

		li.page-links {
			font-size: 1.5rem;
			text-align: center;
			margin: 0.3em 0;
			cursor: pointer;
			// border: 2px solid red;

			a {
				display: inline;
				text-decoration: none;
				color: $Light;
				list-style: none;
			}

			a:hover {
				color: $Alert;
			}
		}
	}
}

/* Desktops and Tablets */
@media only screen and (min-width: 768px) {
	.scour__container {
		main {
			li.page-links {
				font-size: 2rem;
			}
		}
	}
}
