@import '../styles/styles.scss';

.general__container.cookies {
	background: $Light;
	color: $Dark;
	padding-top: 5rem;

	main {
		overflow-y: hidden;

		h1 {
			color: $mainMDark;
			display: flex;
			justify-content: center;
		}
		p {
			text-align: justify;
			margin-bottom: 15rem;
		}
	}
}

// FOR TABLETS & DESKTOPS
@include mQ(768px) {
	.general__container.cookies {
		padding: 0 2em;

		h1 {
			margin-top: 1em;
		}
	}
}
