@import '../styles/styles.scss';

:root {
	--size-1: clamp(2rem, 6vw, 4rem);
	--size-2: clamp(1.5rem, 5vw, 3rem);
	--size-3: clamp(1.2rem, 4vw, 2.5rem);
	--size-4: clamp(1.1rem, 3.5vw, 2rem);
	--size-5: clamp(1rem, 3vw, 1.8rem);
	--size-6: clamp(0.9rem, 2.5vw, 1.5rem);
	--size-7: clamp(0.8rem, 2.2vw, 1.2rem);
	--size-8: clamp(0.7rem, 2.1vw, 1.1rem);

	--sm-shadow: 3px 3px 5px var(--med-color);
	--lg-shadow: 5px 5px 8px var(--med-color);

	--sm-icon: var(--size-3);
	--md-icon: var(--size-1);
	--lg-icon: clamp(3rem, 5.2vw, 5rem);
}

.documents-container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: $Light;
	margin: 0 auto;
	padding: 2rem;
	min-height: 83vh;
	overflow: hidden;
}

.documents-container .documents-title {
	font-weight: 700;
	margin-top: 1rem;
	margin-bottom: 1rem;
	color: $mainColor;
	border: none;
	background-color: transparent;
}

.documents-container .documents-title span {
	font-weight: 500;
	color: $Light;
}

.documents-subtitle {
	margin-top: 3rem;
}

.documents-line-1,
.documents-line-2,
.documents-line-3 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 2em;
	padding: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;

	.document-link-resume,
	.document-link {
		font-weight: 500;
		color: $Light;
		text-decoration: none;
		padding: 1em 2em;
	}
}

.documents-line-1 {
	justify-content: center;
}

.instagram {
	border: 2px solid red;
}

// btn-click & btn-docs for Desktop
@include mQ(1200px) {
	.documents-container .documents-title {
		margin-top: 4rem;
		margin-bottom: 2rem;
	}

	.documents-line-1,
	.documents-line-2,
	.documents-line-3 {
		margin-bottom: 2rem;
	}
}
