@import '../styles/styles.scss';

.form-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 4rem 0;
}

.form-card {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	width: 90%;
	padding: 1.5rem 2rem;
	max-width: 600px;
	border-radius: 20px;
}

form h3 {
	color: #555;
	font-weight: 700;
	margin-bottom: 1.5rem;
	text-align: center;
}

form input,
form textarea {
	border: 0;
	outline: none;
	margin: 10px 0;
	padding: 20px;
	width: 100%;
	background-color: #f5f5f5;
	font-size: 16px;
}

form button {
	padding: 15px;
	background-color: $mainColor;
	color: $Light;
	font-size: 18px;
	width: 150px;
	margin: 20px auto 0;
	border: 0;
	border-radius: 30px;
	outline: none;
	cursor: pointer;
}

.input-container {
	position: relative;
	display: flex;
}

input:not(:placeholder-shown):invalid {
	border: 2px solid red;
}

input:not(:placeholder-shown):invalid + span.validator::after {
	content: '✖';
	color: red;
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}

input:not(:placeholder-shown):valid + span.validator::after {
	content: '✓';
	color: green;
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}
