// RESPONSIVE (VIEWPORT SIZES)
$minWidth: 600px !default;
$maxWidth: 1920px !default;

// Basic Colors
$Dark: rgb(28, 28, 30); // My Black
$MedDark: rgb(58, 58, 60); // My Dark Gray
$MedLight: rgb(142, 142, 147); // My Gray: Silver
$Light: rgb(247, 247, 247); // My White
$Danger: rgb(255, 59, 48); // Apple System Light Red
$Info: rgb(52, 199, 89); // Apple System Light Green
$Alert: rgb(255, 149, 0); // Apple System Light Orange

// Main Color HSL(hue(color), saturation, lightness)
$mainDark: rgb(20, 100, 255);
$mainMDark: rgb(10, 132, 255); // Apple System Dark Blue
$mainColor: rgb(0, 122, 255); // Apple System Light Blue
$mainLight: rgb(100, 210, 255); // Apple System Dark Teal

// Navbar
$navbarColor: rgba(28, 28, 30, 0.97);

// Deg Colors backgrounds
$darkDeg: linear-gradient(90deg, $Dark, $MedDark);
$darkDeg2: linear-gradient(90deg, $MedDark, $Dark);
$mainDeg: linear-gradient(
	to top,
	$mainDark 0%,
	$mainColor 50%,
	$mainColor 50%,
	$mainLight 100%
);
$esfere: radial-gradient(at top left, $mainLight, $mainDark);
$esfere2: radial-gradient(at top left, $mainLight, darken($mainDark, 30%));

// Font-Family
$logo: 'Roboto Flex', sans-serif;
$navbar: 'Roboto Flex', sans-serif;
$title: 'Roboto Flex', sans-serif;
$subTitle: 'Roboto Flex', sans-serif;
$subTitle2: 'Poppins', sans-serif;
$body: 'Poppins', sans-serif;
$buttons: 'Roboto Flex', sans-serif;

// PAGES
$section-pad: 150px 0;
$space: 16px; // margin: 0 0 $space; -sides spaces-
$space-md: calc($space * 2);
$space-lg: calc($space * 3);

// COMPONENTS
$border-rad: 8px;

// SHADOWS
$shadow: 0 0 1em rgba(0, 0, 0, 0.25);
$card-shadow: 0 2px 2px rgba(0, 0, 0, 0.8);
$card-shadow-h: 0 12px 16px rgba(0, 0, 0, 0.8);
$btn-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
$btn-shadow-click: 2px 2px 10px rgba(0, 0, 0, 0.5);
$text-shadow: 1px 1px rgba(0, 0, 0, 0.2);

// Google Colors
$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

$Amazon: #ff9900;
