@import '../styles/styles.scss';

.banner2-container {
	position: relative;
	font-family: 'Roboto Flex';
	display: flex;
	width: 100%;
	max-width: $maxWidth;
	height: 100vh;
	margin-bottom: 2px;
	padding: 0;
	overflow: hidden;
	background-color: $Light;

	.banner-text {
		position: relative;
		width: 100%;
		height: 100%;
		user-select: none;

		span {
			@include font-size(75px, 270px);
			position: absolute;
			padding: 0 1em;
			left: 50%;
			top: 50%;
			transform: translate(-62%, -90%) rotateZ(-90deg);
			font-family: 'Roboto Flex', sans-serif;
			color: rgba(255, 255, 255, 0.5);
			font-weight: bold;
			text-align: center;
			-webkit-text-stroke-width: 1px;
			-webkit-text-stroke-color: $Dark;
			z-index: 2;

			&:hover {
				// background-color: rgba(17, 231, 17, 0.2);
				background-color: rgba(4, 86, 249, 0.2);
			}
		}
	}

	.banner-text-2 {
		position: absolute;
		bottom: 0;
		right: 1em;
		text-align: right;
		color: $Light;
		z-index: 2;
		padding: 1em 2em;
		background-color: rgba(0, 0, 0, 0.5);
		box-shadow: 3px 3px 5px black;
		user-select: none;

		&:hover {
			// background-color: rgba(235, 221, 22, 0.5);
			background-color: rgba(4, 86, 249, 0.2);
		}

		h1 {
			@include font-size(18px, 38px);
			font-weight: 600;
			margin: 0;
			text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
		}

		h2 {
			@include font-size(30px, 60px);
			font-weight: bold;
			margin: 0;
			text-shadow: 0 0 10px rgba(0, 0, 0, 1);
		}

		.btn {
			@include font-size(16px, 30px);
			font-weight: bold;
			margin-top: 0.6em;
			margin-right: 0;
			margin-bottom: 0;
			padding: 0 1em;
			border: 1px solid;
		}
	}

	.banner2 {
		position: absolute;
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-columns: 25fr 30fr 40fr 15fr;
		grid-template-rows: 25fr 35fr 5fr 30fr;
		grid-gap: 0;
		mix-blend-mode: multiply;
		z-index: 1;

		.banner2-img-black {
			background-color: $Dark;

			&:hover {
				background-color: transparent;
			}
		}

		.banner2-img {
			&:hover {
				// background-color: rgba(237, 25, 25, 0.3);
				background-color: lightblue;
			}
		}
	}

	.banner3 {
		position: absolute;
		top: -5%;
		left: -5%;
		width: 110%;
		height: 110%;
		background-repeat: no-repeat;
		background-size: cover;
		animation: pulse 20s linear alternate infinite;
		z-index: 0;
	}

	@keyframes pulse {
		0% {
			background-position: 10%;
			transform: rotate(-5deg);
		}

		100% {
			background-position: 90%;
			transform: rotate(5deg);
		}
	}
}

// FOR MOBILES
@include mQ(200px, 450px) {
	.banner2-container {
		.banner-text-2 {
			bottom: 4em;
			right: 1em;
			left: 1em;
			text-align: center;
			padding: 0.6em;
			h1 {
				font-size: 16px;
			}
			h2 {
				font-size: 20px;
			}
		}
	}
}

// FOR LAPTOP & DESKTOP
@include mQ(768px) {
	.banner2-container {
		.banner-text {
			span {
				transform: translate(-70%, -50%) rotateZ(-90deg);
			}
		}
		.banner-text-2 {
			bottom: 1em;
		}

		@keyframes pulse {
			0% {
				width: 110%;
				height: 110%;
			}

			100% {
				width: 150%;
				height: 150%;
			}
		}
	}
}
